import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "gatsby"

const Trainingen = () => (
  <Layout pageInfo={{ pageName: "trainingen", pageTitle: "Trainingen, workshops en lezingen" }}>
    <Seo title="Trainingen"/>

    <section className="general-page" id="introduction">
      <Container>
        <Row>
          <Col>
            <div className="block">
              <p>
                Natuurlijk is het heel leuk om een tekening door mij te laten analyseren, maar nog leuker is het
                misschien wel om het zelf te leren! Dit geldt niet alleen voor ouders, maar ook voor professionals die
                met kinderen werken, bijvoorbeeld scholen, kinderdagverblijven, peuterspeelzalen, gastouders,
                begeleiders en coaches. Er zijn verschillende mogelijkheden om meer te leren over het analyseren van een
                tekening.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="container" id="trainingen">
      <div className="section-heading">
        <h2 className="title">Trainingen</h2>
      </div>

      <Row>
        <Col>
          <div className="block">
            <p>
              Na het volgen van de training Wondere Wereld van Tekeningen ben je in staat om een tekening te analyseren
              en begrijpen. Je krijgt een unieke inkijk in zowel de ontwikkeling van het kind als zijn innerlijke
              belevingswereld (onder meer emoties, gedachtes en zelfbeeld) en begrijpt wat een kind écht nodig heeft.
              Aan de hand hiervan kan eventueel ook worden ingeschat of een kind begeleiding nodig heeft.
            </p>
          </div>

          <div className="block">
            <p>
              In 5 bijeenkomsten van ongeveer 3 uur leer je alles wat er te weten valt over het analyseren van een
              tekening. Onderwerpen die onder meer aan de orde komen zijn:

              <ul>
                <li>hoe je je voorbereid en wat hierbij komt kijken;</li>
                <li>de ontwikkelingsfasen die zichtbaar zijn in tekeningen;</li>
                <li>het kleurgebruik;</li>
                <li>de vlakverdeling;</li>
                <li>vormen;</li>
                <li>symboliek;</li>
                <li> opvallende signalen.</li>
              </ul>
            </p>
          </div>

          <div className="block">
            <p>
              De trainingen vinden plaats in Steenbergen op maandagochtend van 9.00 uur tot 12.00 uur of
              zaterdagochtend van 9.30 uur tot 12.30 uur. De kosten hiervoor bedragen € 245,- per persoon. Hiervoor
              krijg je een compleet pakket aan theoretische kennis aangeboden, maar je gaat soms ook zelf aan de slag
              waarvoor de benodigde materialen worden verzorgd. Neem om je hiervoor op te geven <Link
              to="/contact">contact</Link> op met Wondere Wereld van Tekeningen.
            </p>

            <p>
              De training kan ook plaatsvinden op locatie. Het aantal deelnemers voor een training op locatie wordt in
              overleg afgesproken. De kosten hiervoor variëren afhankelijk van het aantal deelnemers en de reisafstand.
              Voor meer informatie of een offerte kan <Link to="/contact">contact</Link> worden opgenomen met Wondere
              Wereld van Tekeningen.
            </p>

            <p>
              Met de training Wondere Wereld van Tekeningen kan een certificaat worden behaald.
            </p>
          </div>
        </Col>

      </Row>
    </section>

    <section className="small-margin-container" id="workshops">
      <div className="section-heading">
        <h2 className="title">Workshops</h2>
      </div>

      <Row>
        <Col>
          <div className="block">
            <p>
              Het is ook mogelijk om, zowel afzonderlijk van als naast de training Wondere Wereld van Tekeningen,
              workshops te volgen. Tijdens de workshop krijg je theoretische kennis aangeboden, maar ga je ook zelf aan
              de slag waarvoor de benodigde materialen worden verzorgd.
            </p>
            <p>
              Er zijn verschillende soorten workshops mogelijk, namelijk een algemene workshop en workshops gericht op
              een bepaald onderwerp (boomtekeningen, huistekeningen, menstekeningen of gezinstekeningen). Natuurlijk kan
              altijd in overleg worden gekeken of er mogelijkheden zijn voor een workshop over een onderwerp dat
              hieronder niet wordt genoemd!
            </p>
          </div>

          <h3 className="title">Algemeen</h3>
          <div className="block">
            <p>
              Bij de algemene workshop worden verschillende basisbeginselen besproken. Hierbij kan worden gedacht aan:
              <ul>
                <li>de ontwikkelingsfasen die zichtbaar zijn in tekeningen;</li>
                <li>welke verschillende soorten tekeningen er zijn;</li>
                <li>wat het materiaal dat wordt gebruikt zou kunnen zeggen over het kind;</li>
                <li>etc.</li>
              </ul>
              Tijdens deze workshop krijg je een interessante eerste inkijk in de basis van het analyseren van
              tekeningen en de schat van informatie die tekeningen in zich heeft.
            </p>
          </div>

          <h3 className="title">Boomtekeningen</h3>
          <div className="block">
            <p>
              Een boom staat symbool voor groei en ontwikkeling. In een boomtekening kan de levensloop van het kind
              worden
              gezien, alsmede hoe een kind zichzelf ervaart of in zijn vel zit.
              Tijdens deze workshop wordt behandeld waar de verschillende onderdelen van een boom voor staan, hoe
              bepaalde
              kenmerken van een boom kunnen worden geïnterpreteerd, waar in de levensloop van een kind voor het kind
              ingrijpende gebeurtenissen hebben plaatsgevonden en hoe een kind zich voelt. Het analyseren van een
              boomtekening kan om deze reden ook goed worden ingezet door professionals wanneer er bijvoorbeeld een
              vermoeden is van dat het kind een ingrijpende gebeurtenis of trauma heeft meegemaakt maar onduidelijk
              is wat dit is geweest of wanneer het heeft plaatsgevonden.
            </p>
          </div>

          <h3 className="title">Huistekeningen</h3>
          <div className="block">
            <p>
              Een huis staat symbool voor de innerlijke belevingswereld en de fantasiewereld van een kind. In een
              huistekening kan worden gezien hoe de thuisbasis van een kind is en hoe hij het gezinsleven en zichzelf
              ervaart.
              Tijdens deze workshop wordt behandeld waar de verschillende onderdelen van een huis voor staan, hoe
              bepaalde onderdelen kunnen worden geïnterpreteerd en hoe het kind zijn basis (thuis en/of zijn gezin)
              ervaart. Het analyseren van huistekeningen kan om deze reden ook goed worden ingezet door professionals
              wanneer er bijvoorbeeld een vermoeden is van problemen in of rondom het gezin of een vermoeden van
              hechtingsproblematiek.
            </p>
          </div>

          <h3 className="title">Menstekeningen</h3>
          <div className="block">
            <p>
              Een kind tekent vaak zichzelf. Een menstekening laat zien hoe een kind zichzelf ervaart: de innerlijke
              beleving komt tot uiting.
              Tijdens deze workshop wordt behandeld waar de verschillende lichaamsdelen van een persoon voor staan
              (hierbij moet ook worden gedacht aan hoe bepaalde kleding of sieraden die zijn getekend kan worden
              geïnterpreteerd) en hoe een kind zichzelf ziet. Het analyseren van menstekeningen kan om deze reden ook
              goed worden ingezet door professionals wanneer er bijvoorbeeld vragen of zorgen zijn over het zelfbeeld
              van een kind.
            </p>
          </div>

          <h3 className="title">Gezinstekeningen</h3>
          <div className="block">
            <p>
              Een gezinstekening geeft de onderlinge verhoudingen (in de ogen van de tekenaar) binnen een gezin weer.
              Tijdens deze workshop wordt de positie van het kind binnen het gezin behandeld, hoe de overige gezinsleden
              zich tot elkaar verhouden, met welke gezinsleden het kind zich identificeert en met welke gezinsleden een
              kind graag meer in contact en/of verbinding zou willen komen. Het analyseren van gezinstekeningen kan om
              deze reden ook goed worden ingezet door professionals wanneer er bijvoorbeeld een vermoeden is van
              problemen in de thuissituatie of wanneer het kind een echtscheiding van ouders heeft meegemaakt.
            </p>
            <p>
              De workshops duren ongeveer 2,5 uur en kunnen zowel worden gegeven in Steenbergen als op
              locatie elders. De workshops in Steenbergen vinden plaats op maandagochtend van 9.00 uur tot 11.30 uur of
              zaterdagochtend van 9.30 uur tot 12.00 uur. De kosten hiervoor bedragen € 39,95 per persoon. Neem om je
              hiervoor op te geven <Link to="/contact">contact</Link> op met Wondere Wereld van Tekeningen.
            </p>
            <p>
              Voor een workshop op locatie variëren
              de kosten afhankelijk van het aantal deelnemers en de reisafstand. Natuurlijk is het mogelijk om de
              workshop helemaal aan te passen aan de interesse of het werkgebied van de deelnemers. Voor meer informatie
              of een offerte kan <Link to="/contact">contact</Link> worden opgenomen met Wondere Wereld van
              Tekeningen.
            </p>
          </div>
        </Col>
      </Row>
    </section>

    <section className="container" id="lezingen">
      <div className="section-heading">
        <h2 className="title">Lezingen</h2>
      </div>

      <Row>
        <Col>
          <div className="block">
            <p>
              Naast de training en workshops kan ik een lezing komen geven. De kosten hiervoor variëren afhankelijk van
              het aantal deelnemers en de reisafstand. Ook wanneer het een lezing betreft kan het onderwerp van de
              lezing helemaal aangepast worden aan de interesse of het werkgebied van de deelnemers.
            </p>
            <p>
              Voor meer informatie of een offerte kan <Link to="/contact">contact</Link> worden opgenomen met Wondere
              Wereld van Tekeningen.
            </p>
          </div>
        </Col>
      </Row>
    </section>

  </Layout>
)

export default Trainingen
